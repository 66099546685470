import { FC, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom'; // 2022-01-19: In react-router-dom v6, "Switch" is replaced by routes "Routes".
import CustomRouter from '@globalComponents/CustomRouter';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import {
  getTenant,
  setPriceGroup,
  setProductId,
  setTenant,
  setTheme,
  getTheme,
  setVariantId,
} from '@redux/ConfigSlice';
import { isAFalabellaTenant, isValidTenant } from '@globalUtils/tenantValidations';
import { nextPosition } from '@redux/StepperSlice';
import { UiKitProvider } from '@digital-retail/journey-ui-kit';
import i18next from 'i18next';
import { getSelectedBoardAsync } from '@redux/ConfirmTypeOfWoodSlice';

const ADOBE_DTM_SCRIPT_URLS = {
  SOAR: process.env.REACT_APP_SCRIPT_URL_ADOBE_DTM_SOAR,
  SOUY: process.env.REACT_APP_SCRIPT_URL_ADOBE_DTM_SOUY,
  SOCO: process.env.REACT_APP_SCRIPT_URL_ADOBE_DTM_SOCO,
  SOPE: process.env.REACT_APP_SCRIPT_URL_ADOBE_DTM_SOPE,
  SOBR: process.env.REACT_APP_SCRIPT_URL_ADOBE_DTM_SOBR,
  SOMX: process.env.REACT_APP_SCRIPT_URL_ADOBE_DTM_SOMX,
  FACL: process.env.REACT_APP_SCRIPT_URL_ADOBE_DTM_FACL,
  FAPE: process.env.REACT_APP_SCRIPT_URL_ADOBE_DTM_FAPE,
  FACO: process.env.REACT_APP_SCRIPT_URL_ADOBE_DTM_FACO,
};

const App: FC = () => {
  const queryParams = new URLSearchParams(useLocation().search);
  const dispatch = useAppDispatch();
  const tenant = useAppSelector(getTenant).toUpperCase();
  const theme = useAppSelector(getTheme);

  useEffect(() => {
    const tenantByParams = queryParams.get('tenant') || 'socl';
    const priceGroupByParams = queryParams.get('priceGroup') || '96';
    const productId = queryParams.get('productId') || '';
    const variantId = queryParams.get('variantId') || '';

    dispatch(setPriceGroup(priceGroupByParams));
    dispatch(setProductId(productId));
    dispatch(setVariantId(variantId));

    if (isValidTenant(tenantByParams)) {
      dispatch(setTenant(tenantByParams));

      if (tenantByParams.toLocaleLowerCase() === 'sobr') i18next.changeLanguage('pt');

      if (isAFalabellaTenant(tenantByParams)) {
        dispatch(setTheme('falabella'));
        dispatch(nextPosition());
      }

      if (productId !== '')
        dispatch(
          getSelectedBoardAsync({
            tenant: tenantByParams,
            priceGroup: priceGroupByParams,
            productId,
          })
        );
    }
  }, []);

  useEffect(() => {
    const digitalDataScript = document.createElement('script');
    digitalDataScript.type = 'text/javascript';
    digitalDataScript.innerHTML = `window.digitalData = window.digitalData || ${JSON.stringify({
      ecom: {},
      login: {},
      page: {},
      server: {},
    })};`;
    document.head.appendChild(digitalDataScript);

    const adobeDtmScript = document.createElement('script');
    adobeDtmScript.src = ADOBE_DTM_SCRIPT_URLS[tenant];
    adobeDtmScript.async = true;
    if (ADOBE_DTM_SCRIPT_URLS[tenant]) {
      document.head.appendChild(adobeDtmScript);
    }
  }, [tenant]);

  return (
    <UiKitProvider theme={theme}>
      <div data-testid="app">
        <Routes>
          <Route index element={<CustomRouter />} />
        </Routes>
      </div>
    </UiKitProvider>
  );
};

export default App;
