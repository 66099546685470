import { FC } from 'react';
import { Radio, RadioOption } from '@digital-retail/journey-ui-kit';
import { Container } from './styles';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { getDeliverySelection, setDeliverySelection } from '@redux/WoodSlice';
import { DELIVERY_OPTIONS } from './constants';
import TextWithBold from '@globalComponents/TextWithBold';

const DeliverySelection: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const selectedDeliveryOption = useAppSelector(getDeliverySelection);

  const onChangeDeliverySelection = (value: string) => {
    dispatch(setDeliverySelection(value));
  };

  return (
    <Container data-testid="how-to-receive">
      <Radio onChange={onChangeDeliverySelection} value={selectedDeliveryOption}>
        <div style={{ marginBottom: '6px' }}>
          <RadioOption value={DELIVERY_OPTIONS.ADDTOCART_AND_DOWNLOAD}>
            <TextWithBold text={t('ADDTOCART_AND_DOWNLOAD')} />
          </RadioOption>
        </div>
        <RadioOption value={DELIVERY_OPTIONS.JUST_DOWNLOAD}>
          <TextWithBold text={t('JUST_DOWNLOAD')} />
        </RadioOption>
      </Radio>
    </Container>
  );
};

export default DeliverySelection;
