import { useAppDispatch, useAppSelector } from '@app/hooks';
import { Button } from '@digital-retail/journey-ui-kit';
import { SidebarWrapperStyled } from '@features/CuttingResult/styles';
import { DELIVERY_OPTIONS } from '@features/DeliverySelection/constants';
import { BREAKPOINTS } from '@globalUtils/constants/breakpoints';
import { useWindowSize } from '@hooks/useWindowSize';
import { getConfirmedStatus, getOptimizationResult } from '@redux/OptimizationSlice';
import { nextPosition, previousPosition } from '@redux/StepperSlice';
import { getDeliverySelection } from '@redux/WoodSlice';
import { useTranslation } from 'react-i18next';
import { saveAs } from 'file-saver';
import { getVariantId } from '@redux/ConfigSlice';

const CuttingResultSidebar: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const confirmedStatus = useAppSelector(getConfirmedStatus);
  const widthScreen = useWindowSize().width || 0;
  const selectedDeliveryOption = useAppSelector(getDeliverySelection);
  const optimizationResult = useAppSelector(getOptimizationResult);
  const filePath = optimizationResult?.url_planos;
  const quantity = optimizationResult?.cant_placas;
  const variantId = useAppSelector(getVariantId);

  const goBack = () => {
    dispatch(previousPosition());
  };

  const handleDownload = async () => {
    try {
      if (filePath) {
        await saveAs(filePath, 'file.pdf');
      }
    } catch (error) {
      // TODO: Add error handling
    }
  };

  const getCartlineNumber = (filePath: string, variantId: string) => {
    try {
      const docId = filePath.split('/').pop()?.split('.')[0] || '';
      return `${variantId}${docId}`;
    } catch (error) {
      return '';
    }
  };

  const handleAddToCart = () => {
    if (!filePath || !quantity || !variantId) return;

    const customCartLineNumber = getCartlineNumber(filePath, variantId);

    const customInfo = [
      { name: 'journeyType', values: ['WOODS'], group: 'journey-woods' },
      {
        name: 'pdf_url',
        values: [filePath],
        group: 'journey-woods',
      },
    ];

    window.parent.postMessage(
      {
        eventType: 'journeysWoodCutOptimizer',
        action: 'addToCart',
        data: {
          filePath,
          quantity,
          customInfo,
          customCartLineNumber,
        },
      },
      '*'
    );
  };

  return (
    <SidebarWrapperStyled>
      <Button
        data-testid="back-button"
        onClick={() => goBack()}
        fullWidth={widthScreen < BREAKPOINTS.tablet}
        outline
      >
        {t('GO_BACK')}
      </Button>
      {selectedDeliveryOption === DELIVERY_OPTIONS.ADDTOCART_AND_DOWNLOAD ? (
        <>
          <Button
            disabled={!confirmedStatus}
            data-testid="download-button"
            onClick={handleDownload}
            fullWidth={widthScreen < BREAKPOINTS.tablet}
          >
            {t('DOWNLOAD_BUTTON_LABEL')}
          </Button>
          <Button
            disabled={!confirmedStatus}
            data-testid="add-to-cart-button"
            onClick={handleAddToCart}
            fullWidth={widthScreen < BREAKPOINTS.tablet}
          >
            {t('ADDTOCART_BUTTON_LABEL')}
          </Button>
        </>
      ) : (
        <Button
          disabled={!confirmedStatus}
          data-testid="download-button"
          onClick={() => dispatch(nextPosition())}
          fullWidth={widthScreen < BREAKPOINTS.tablet}
        >
          {t('DOWNLOAD_RESULTS')}
        </Button>
      )}
    </SidebarWrapperStyled>
  );
};

export default CuttingResultSidebar;
