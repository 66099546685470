import { mediaQuery } from '@globalUtils/mediaQuery';
import styled from 'styled-components';

export const SidebarWrapperStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px;
  padding: 8px 16px 16px 16px;
  width: 100%;
  box-sizing: border-box;

  ${mediaQuery.mobileOnly(`
    flex-direction: column-reverse;
    padding: 8px 16px;
  `)}
`;

export const Container = styled.div`
  padding: 0 16px;
`;
