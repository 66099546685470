import CutList from '@globalComponents/CutList';
import { Description } from '@globalComponents/Text/Description';
import {
  SkuStyled,
  SectionSubtitleStyled,
  WoodDescriptionStyled,
  WoodDetailWrapperStyled,
  WoodImageStyled,
  NameItemStyled,
  TextWrapperStyled,
  WoodQuantityDescriptionStyled,
  PlaneWrapperStyled,
  TextBoardPosition,
  ActionBoardContainer,
  BetaSwitchContainer,
  Price,
  AdviceDescription,
  RowContainer,
  EdgeBandingIndicatorContainer,
  FooterBoard,
  SummaryContainer,
  ProductSummary,
  ImageContainer,
  QuantityBadge,
  ProductDetails,
  BrandName,
  ProductName,
  PriceRow,
  PriceLabel,
  PriceValue,
  DimensioningNote,
  Divider,
} from './styles';
import { SubTitle } from '@globalComponents/Text/SubTitle';
import Canvas from '@globalComponents/Canvas';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import {
  getOptimizationResult,
  getBoardPosition,
  setBoardPosition,
  getVisibleVein,
  setVisibleVein,
  getConfirmedStatus,
  setConfirmedStatus,
} from '@redux/OptimizationSlice';
import { useEffect, useState, useMemo } from 'react';
import { Check, Highlight, PillButton, Switch } from '@digital-retail/journey-ui-kit';
import { ReactComponent as LeftArrow } from '@assets/icons/left-arrow.svg';
import { ReactComponent as RightArrow } from '@assets/icons/right-arrow.svg';
import { BASE_COLOR } from '@globalUtils/constants/baseColor';
import { getSelectedProduct } from '@redux/ConfirmTypeOfWoodSlice';
import { currencyFormat } from '@globalUtils/currencyFormat';
import { ReactComponent as EdgebandinIndicator } from '@assets/icons/edgebanding-indicator.svg';
import { getProjectName } from '@redux/WoodSlice';
import { useTranslation } from 'react-i18next';
import { getTenant } from '@redux/ConfigSlice';
import { PRICE_TYPES } from './constants';

const CuttingResult = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const tenant = useAppSelector(getTenant);
  const isFalabella = useMemo(() => tenant.toLowerCase().startsWith('fa'), [tenant]);
  const visibleVein = useAppSelector(getVisibleVein);
  const optimizationResult = useAppSelector(getOptimizationResult);
  const projectName = useAppSelector(getProjectName);
  const boardPosition = useAppSelector(getBoardPosition);
  const confirmedStatus = useAppSelector(getConfirmedStatus);
  const [totalBoard, setTotalBoard] = useState(0);
  const [totalSameBoard, setTotalSameBoard] = useState(0);
  const [ableToNext, setAbleToNext] = useState(true);
  const [ableToBack, setAbleToBack] = useState(true);
  const selectedProduct = useAppSelector(getSelectedProduct);
  const productPrice = selectedProduct?.price;
  const hasEdgeBandings = optimizationResult?.tapacantos.length !== 0;

  const prices = useMemo(() => {
    const result = {
      cmrPrice: 0,
      internetPrice: 0,
      normalPrice: 0,
    };

    if (isFalabella && selectedProduct?.price?.allPrices) {
      selectedProduct.price.allPrices.forEach((price) => {
        switch (price.type) {
          case PRICE_TYPES.CMR:
            result.cmrPrice = price.priceWithoutFormatting;
            break;
          case PRICE_TYPES.INTERNET:
            result.internetPrice = price.priceWithoutFormatting;
            break;
          case PRICE_TYPES.NORMAL:
            result.normalPrice = price.priceWithoutFormatting;
            break;
        }
      });
    }

    return result;
  }, [selectedProduct]);

  const regularPrice = prices.internetPrice || prices.normalPrice || 0;

  useEffect(() => {
    if (optimizationResult?.cant_placas) {
      const result = optimizationResult;
      setTotalSameBoard(result.planos_corte.length);
      setTotalBoard(result.cant_placas);
    }
    setAbleToNext(boardPosition + 2 <= totalSameBoard);
    setAbleToBack(boardPosition - 1 >= 0);
  }, [optimizationResult, boardPosition, totalSameBoard]);

  const onNextBoard = () => {
    ableToNext && dispatch(setBoardPosition(boardPosition + 1));
  };

  const onPrevBoard = () => {
    ableToBack && dispatch(setBoardPosition(boardPosition - 1));
  };

  const changeVisibleVein = () => {
    dispatch(setVisibleVein(!visibleVein));
  };

  const onConfirmedStatus = () => {
    dispatch(setConfirmedStatus(!confirmedStatus));
  };

  return (
    <>
      <Description>{t('CHECK_CUTS_DESCRIPTION')}</Description>
      <SectionSubtitleStyled isFalabella={isFalabella}>
        <SubTitle>{projectName}</SubTitle>
      </SectionSubtitleStyled>
      {!isFalabella && (
        <>
          <WoodDetailWrapperStyled data-testid="wood-detail-wrapper">
            <div className="wood-image">
              <WoodImageStyled src={selectedProduct?.imageURL} />
            </div>
            <div className="wood-detail">
              <NameItemStyled>{t('BOARD')}</NameItemStyled>
              <WoodDescriptionStyled>{selectedProduct?.name}</WoodDescriptionStyled>
              <SkuStyled>SKU {selectedProduct?.id}</SkuStyled>
            </div>
          </WoodDetailWrapperStyled>
          <TextWrapperStyled>
            <NameItemStyled>{t('BOARDS_QUANTITY')}</NameItemStyled>
            <WoodQuantityDescriptionStyled>
              {t('NUMBER_OF_BOARDS', { boards: totalBoard })}
              {totalBoard > 1 && 's'}
            </WoodQuantityDescriptionStyled>
          </TextWrapperStyled>
        </>
      )}
      <SectionSubtitleStyled>
        <SubTitle>{t('PLANS_AND_CUTS')}</SubTitle>
      </SectionSubtitleStyled>
      <PlaneWrapperStyled>
        <Highlight variant="notTransactional">
          <TextBoardPosition>
            {`${boardPosition + 1} ${t('BOARD')}${
              boardPosition > 0 ? 's' : ''
            } de ${totalSameBoard}`}
          </TextBoardPosition>
        </Highlight>
        <ActionBoardContainer>
          <PillButton
            data-testid="back-board"
            disabled={!ableToBack}
            size="xs"
            variant="ghost"
            onClick={() => onPrevBoard()}
            iconPosition="start"
            Icon={LeftArrow}
            iconStyle={{
              fill: BASE_COLOR.primary,
            }}
          >
            {t('PREVIOUS')}
          </PillButton>
          <PillButton
            data-testid="next-board"
            disabled={!ableToNext}
            size="xs"
            variant="ghost"
            onClick={() => onNextBoard()}
            iconPosition="end"
            Icon={RightArrow}
            iconStyle={{
              fill: BASE_COLOR.primary,
            }}
          >
            {t('NEXT')}
          </PillButton>
        </ActionBoardContainer>
        <Canvas />
        <FooterBoard hasEdgeBandings={hasEdgeBandings}>
          <BetaSwitchContainer>
            <p>{t('SEE_VETA')}</p>
            <Switch
              data-testid="visible-vein-input"
              active={!!visibleVein}
              onChange={changeVisibleVein}
            />
          </BetaSwitchContainer>
          {hasEdgeBandings && (
            <EdgeBandingIndicatorContainer>
              <EdgebandinIndicator />
              <p>{t('EDGEBANDING')}</p>
            </EdgeBandingIndicatorContainer>
          )}
        </FooterBoard>
      </PlaneWrapperStyled>
      <SectionSubtitleStyled>
        <SubTitle>{t('CUTS_DETAILS')}</SubTitle>
      </SectionSubtitleStyled>
      <CutList idVisible />
      <hr />
      <TextWrapperStyled>
        {!isFalabella && (
          <>
            <NameItemStyled>{t('TOTAL')}</NameItemStyled>
            <Price>
              {productPrice !== undefined &&
                currencyFormat({
                  number: productPrice.priceWithoutFormatting * totalBoard,
                  currencySymbol: productPrice.currencySymbol,
                  thousandSeparator: productPrice.thousandSeparator,
                  decimalSeparator: productPrice.decimalSeparator,
                  precision: productPrice.precision,
                })}
            </Price>
            <AdviceDescription>{t('PRICE_DESCRIPTION')}</AdviceDescription>
          </>
        )}

        {isFalabella && (
          <SummaryContainer data-testid="summary-container">
            <ProductSummary>
              <ImageContainer showBorder={false}>
                <WoodImageStyled src={selectedProduct?.imageURL} />
              </ImageContainer>
              <ProductDetails>
                <QuantityBadge>x{totalBoard}</QuantityBadge>
                <BrandName>{selectedProduct?.brandID}</BrandName>
                <ProductName>{selectedProduct?.name}</ProductName>
              </ProductDetails>
            </ProductSummary>
            <Divider />
            {prices.cmrPrice ? (
              <>
                <PriceRow>
                  <PriceLabel>{t('TOTAL_WITH_CMR')}</PriceLabel>
                  <PriceValue>
                    {productPrice !== undefined &&
                      currencyFormat({
                        number: prices.cmrPrice * totalBoard,
                        currencySymbol: productPrice?.currencySymbol,
                        thousandSeparator: productPrice?.thousandSeparator,
                        decimalSeparator: productPrice?.decimalSeparator,
                        precision: productPrice?.precision,
                      })}
                  </PriceValue>
                </PriceRow>
                <PriceRow weight={400}>
                  <PriceLabel>{t('TOTAL_WITHOUT_CMR')}</PriceLabel>
                  <PriceValue>
                    {productPrice !== undefined &&
                      currencyFormat({
                        number: regularPrice * totalBoard,
                        currencySymbol: productPrice?.currencySymbol,
                        thousandSeparator: productPrice?.thousandSeparator,
                        decimalSeparator: productPrice?.decimalSeparator,
                        precision: productPrice?.precision,
                      })}
                  </PriceValue>
                </PriceRow>
              </>
            ) : (
              <PriceRow weight={400}>
                <PriceLabel>{t('TOTAL')}</PriceLabel>
                <PriceValue>
                  {productPrice !== undefined &&
                    currencyFormat({
                      number: regularPrice * totalBoard,
                      currencySymbol: productPrice?.currencySymbol,
                      thousandSeparator: productPrice?.thousandSeparator,
                      decimalSeparator: productPrice?.decimalSeparator,
                      precision: productPrice?.precision,
                    })}
                </PriceValue>
              </PriceRow>
            )}
            <DimensioningNote>{t('DIMENSIONING_NO_COST')}</DimensioningNote>
          </SummaryContainer>
        )}

        <RowContainer>
          <Check
            checked={confirmedStatus}
            onChange={onConfirmedStatus}
            data-testid="checkbox-confirmed-input"
            label={t('REVIEW_CONFIRMATION')}
          />
        </RowContainer>
      </TextWrapperStyled>
    </>
  );
};

export default CuttingResult;
