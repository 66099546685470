import { FC } from 'react';

interface TextWithBoldProps {
  text: string;
}

const TextWithBold: FC<TextWithBoldProps> = ({ text }) => {
  const parts = text.split(/(<b>.*?<\/b>)/g);

  return (
    <>
      {parts.map((part, i) => {
        if (part.startsWith('<b>') && part.endsWith('</b>')) {
          return <strong key={i}>{part.slice(3, -4)}</strong>;
        }
        return part;
      })}
    </>
  );
};

export default TextWithBold;
