export const closeModal = () => {
  const iframeSrc = window.location.href;
  const urlParams = new URLSearchParams(new URL(iframeSrc).search);
  const tenant = (urlParams.get('tenant') || '').toLowerCase();

  if (tenant === 'facl' || tenant === 'fape') {
    window.parent.postMessage(
      {
        eventType: 'journeysWoodCutOptimizer',
        action: 'closeModal',
        data: {},
      },
      '*'
    );
  } else {
    const message = JSON.stringify({
      message: 'closeModal',
    });
    window.parent.postMessage(message, '*');
  }
};
