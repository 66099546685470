import { useAppSelector } from '@app/hooks';
import { getCurrentPosition } from '@redux/StepperSlice';
import Layout from '@globalComponents/Layout';
import { getHeader, getMain, getSidebar } from './utils';
import { VIEWS } from '@globalUtils/constants/views';
import { getTenant } from '@redux/ConfigSlice';

const CustomRouter = () => {
  const position = useAppSelector(getCurrentPosition);
  const tenant = useAppSelector(getTenant).toLowerCase();
  let filteredViews = VIEWS;
  if (tenant != 'facl') {
    filteredViews = VIEWS.filter((view) => view.title != 'DeliverySelection');
  }
  const MainComponent = getMain({ position, VIEWS: filteredViews });
  const SidebarComponent = getSidebar({ position, VIEWS: filteredViews });
  const HeaderComponent = getHeader({ position, VIEWS: filteredViews });

  return (
    <Layout
      data-testid="custom-router"
      withStepper={false}
      header={HeaderComponent !== null ? <HeaderComponent /> : <></>}
      sidebar={SidebarComponent !== null ? <SidebarComponent /> : <></>}
    >
      <MainComponent />
    </Layout>
  );
};

export default CustomRouter;
