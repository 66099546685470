import { FC } from 'react';
import { Button } from '@digital-retail/journey-ui-kit';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { nextPosition, previousPosition } from '@redux/StepperSlice';
import { SidebarWrapperStyled } from '../../styles';
import { getDeliverySelection } from '@redux/WoodSlice';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '@hooks/useWindowSize';
import { BREAKPOINTS } from '@globalUtils/constants/breakpoints';

const DeliverySelectionSidebar: FC = () => {
  const dispatch = useAppDispatch();
  const selectedDeliveryOption = useAppSelector(getDeliverySelection);
  const { t } = useTranslation();
  const widthScreen = useWindowSize().width || 0;
  const isFullWidth = widthScreen < BREAKPOINTS.tablet;

  return (
    <SidebarWrapperStyled>
      <Button
        data-testid="back-button"
        onClick={() => dispatch(previousPosition())}
        variant="notTransactional"
        outline
        fullWidth={isFullWidth}
      >
        {t('BACK_BUTTON')}
      </Button>
      <Button
        data-testid="continue-button"
        disabled={selectedDeliveryOption.length === 0}
        onClick={() => dispatch(nextPosition())}
        fullWidth={isFullWidth}
      >
        {t('CONTINUE_BUTTON')}
      </Button>
    </SidebarWrapperStyled>
  );
};

export default DeliverySelectionSidebar;
