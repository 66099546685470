import { HeaderWrapperStyled, TitleStyled } from '@globalComponents/Layout/styles';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const DeliverySelectionHeader: FC = () => {
  const { t } = useTranslation();
  return (
    <HeaderWrapperStyled>
      <TitleStyled>{t('DELIVERY_SELECTION_TITLE')}</TitleStyled>
    </HeaderWrapperStyled>
  );
};

export default DeliverySelectionHeader;
